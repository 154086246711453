<template>
  <section class="case-creation">
    <div
      class="case-creation__step case-creation__cpt-information"
      v-if="!isBillingUser"
      v-loading="loading"
    >
      <div class="case-creation__card">
        <div class="case-creation__heading">
          <div class="title-with-icon">
            <span class="icon">
              <img
                src="@/assets/images/icons/icon-billing-info.svg"
                alt="icon"
                class="img-fluid"
              />
            </span>
            <h3 class="title">CPT Codes</h3>
          </div>
          <div class="cpt-el-select" v-if="hasUTIPanel && !viewForm">
            <el-select
              v-model="utiCPT"
              placeholder="Select Category"
              multiple
              @change="setCPTCodesAutofill"
              filterable
              default-first-option
              collapse-tags
            >
              <el-option
                v-for="(cpt, index) in this.utiCPTCodesData"
                :key="index"
                :label="cpt.category"
                :value="cpt.category"
              ></el-option>
            </el-select>
            <span class="search-icon">
              <img
                src="@/assets/images/icons/search-icon-with-bg-red.svg"
                alt="icon"
                class="img-fluid"
              />
            </span>
          </div>
          <div v-if="viewForm">
            <el-button class="edit-btn" @click="updatedEditForm">
              EDIT
            </el-button>
            <el-button
              class="edit-btn"
              @click="syncTestinfo"
              v-if="hasBloodPanel"
            >
              Sync Testinfo
            </el-button>
          </div>

          <div v-if="hasBloodPanel && !viewForm">
            <el-button class="edit-btn" @click="syncTestinfo">
              Sync Testinfo
            </el-button>
          </div>
        </div>
        <div class="case-creation__cpt-information__body" v-if="viewForm">
          <div class="case-creation__tabs cpt-information-tabs view">
            <el-table :data="addCPT">
              <el-table-column label="CPT Code" prop="cpt_code">
                <template #default="scope">
                  <span>{{ scope.row.cpt_code }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Test Name"
                width="200"
                prop="test_name"
                v-if="hasBloodPanel"
              >
                <template #default="scope">
                  <span>{{
                    scope.row.test_name ? scope.row.test_name : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="M1" prop="m1">
                <template #default="scope">
                  <span>{{ scope.row.m1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="M2" prop="m2">
                <template #default="scope">
                  <span>{{ scope.row.m2 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="M3" prop="m3">
                <template #default="scope">
                  <span>{{ scope.row.m3 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="M4" prop="m4">
                <template #default="scope">
                  <span>{{ scope.row.m4 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="ICD-1" prop="icd_1">
                <template #default="scope">
                  <span>{{ scope.row.icd_1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="ICD-2" prop="icd_2">
                <template #default="scope">
                  <span>{{ scope.row.icd_2 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="ICD-3" prop="icd_3">
                <template #default="scope">
                  <span>{{ scope.row.icd_3 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="ICD-4" prop="icd_4">
                <template #default="scope">
                  <span>{{ scope.row.icd_4 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Unit" prop="unit">
                <template #default="scope">
                  <span>{{ scope.row.unit }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Charge" prop="charge">
                <template #default="scope">
                  <span>{{ scope.row.charge }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Total" prop="total">
                <template #default="scope">
                  <span>{{ scope.row.total }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Remarks" width="140" prop="remarks">
                <template #default="scope">
                  <el-button
                    placement="top"
                    class="title-on-hover"
                    :title="scope.row.remarks"
                    @click="scope.row.remarks;"
                  >
                    <span class="remark-value">{{ scope.row.remarks }}</span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="add-cpt-btn" v-if="viewForm">
              <div class="total-sum-count pr-10" v-if="totalUnitsSum">
                <p class="total-sum">
                  Total Sum :
                  <span class="count">
                    (
                    {{ Math.round(totalUnitsSum) }}
                    )
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="case-creation__cpt-information__body" v-else>
          <div class="case-creation__tabs cpt-information-tabs">
            <el-table :data="addCPT">
              <el-table-column label="CPT Code" width="100" prop="cpt_code">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.cpt_code"
                    placeholder="CPT"
                    @input="updateChargeOnCPTChange(scope.row)"
                  ></el-input>
                  <p class="err" v-if="scope.row.cptCodeError">
                    CPT Code is required
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                label="Test Name"
                width="200"
                prop="test_name"
                v-if="hasBloodPanel"
              >
                <template #default="scope">
                  <el-input
                    v-model="scope.row.test_name"
                    disabled
                    placeholder="Test Name"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="M1" prop="m1">
                <template #default="scope">
                  <el-input v-model="scope.row.m1" placeholder="M1"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="M2" prop="m2">
                <template #default="scope">
                  <el-input v-model="scope.row.m2" placeholder="M2"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="M3" prop="m3">
                <template #default="scope">
                  <el-input v-model="scope.row.m3" placeholder="M3"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="M4" prop="m4">
                <template #default="scope">
                  <el-input v-model="scope.row.m4" placeholder="M4"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="ICD-1" prop="icd_1">
                <template #header>
                  <div class="cpt-checkbox">
                    <span class="header">ICD-1</span>
                    <span class="search-checkbox">
                      <el-checkbox
                        v-model="icdCheckboxChecked"
                        @change="fillICD1()"
                      ></el-checkbox>
                    </span>
                  </div>
                </template>
                <template #default="scope">
                  <div>
                    <el-input
                      v-model="scope.row.icd_1"
                      placeholder="ICD-1"
                    ></el-input>
                    <p class="err" v-if="scope.row.icdError">
                      ICD-1 is required
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="ICD-2" prop="icd_2">
                <template #header>
                  <div class="cpt-checkbox">
                    <span class="header">ICD-2</span>
                    <span class="search-checkbox">
                      <el-checkbox
                        v-model="icd2CheckboxChecked"
                        @change="fillICD2()"
                      ></el-checkbox>
                    </span>
                  </div>
                </template>
                <template #default="scope">
                  <div>
                    <el-input
                      v-model="scope.row.icd_2"
                      placeholder="ICD-2"
                    ></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="ICD-3" prop="icd_3">
                <template #header>
                  <div class="cpt-checkbox">
                    <span class="header">ICD-3</span>
                    <span class="search-checkbox">
                      <el-checkbox
                        v-model="icd3CheckboxChecked"
                        @change="fillICD3()"
                      ></el-checkbox>
                    </span>
                  </div>
                </template>
                <template #default="scope">
                  <div>
                    <el-input
                      v-model="scope.row.icd_3"
                      placeholder="ICD-3"
                    ></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="ICD-4" prop="icd_4">
                <template #header>
                  <div class="cpt-checkbox">
                    <span class="header">ICD-4</span>
                    <span class="search-checkbox">
                      <el-checkbox
                        v-model="icd4CheckboxChecked"
                        @change="fillICD4()"
                      ></el-checkbox>
                    </span>
                  </div>
                </template>
                <template #default="scope">
                  <div>
                    <el-input
                      v-model="scope.row.icd_4"
                      placeholder="ICD-4"
                    ></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Unit" prop="unit">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.unit"
                    placeholder="Unit"
                    @input="updateTotal(scope.row)"
                  ></el-input>
                  <p v-if="scope.row.unitError" class="err">Unit is required</p>
                </template>
              </el-table-column>
              <el-table-column label="Charge" prop="charge">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.charge"
                    placeholder="Charge"
                    @input="updatechareTotal(scope.row)"
                    :disabled="true"
                  ></el-input>
                  <p v-if="scope.row.chargeError" class="err">
                    Charge is required
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="Total" prop="total">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.total"
                    placeholder="Total"
                    disabled
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Remarks" width="100" prop="remarks">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.remarks"
                    placeholder="Remarks"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Actions" prop="actions" width="120">
                <template #default="scope">
                  <ul class="action-buttons">
                    <li>
                      <el-button
                        class="view-button"
                        @click="addNewRow(scope.row)"
                      >
                        <img
                          class="action-active"
                          src="@/assets/images/icons/actions/plus-icon.svg"
                          alt="icon"
                        />
                        <img
                          class="action-inactive"
                          src="@/assets/images/icons/actions/plus-inactive.svg"
                          alt="icon"
                        />
                      </el-button>
                    </li>
                    <li>
                      <el-button
                        class="view-button"
                        :disabled="addCPT.length == 1"
                        @click="removeRow(scope.row)"
                      >
                        <img
                          class="action-active"
                          src="@/assets/images/icons/actions/minus-icon.svg"
                          alt="icon"
                        />
                        <img
                          class="action-inactive"
                          src="@/assets/images/icons/actions/minus-inactive.svg"
                          alt="icon"
                        />
                      </el-button>
                    </li>
                  </ul>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="add-cpt-btn">
            <div class="total-sum-count">
              <p class="total-sum" v-if="totalSum">
                Total Sum :
                <span class="count">{{ Math.round(totalSum) }} </span>
              </p>
            </div>
            <el-button
              class="add-btn"
              @click="addCPTCodes"
              :disabled="!isAddCPTButtonDisabled"
              >Update</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import { CPTCodes } from "@/config/constants/searchConstants";
export default {
  data() {
    return {
      loading: false,
      addCPT: [
        {
          cpt_code: "",
          test_name: "",
          m1: "",
          m2: "",
          m3: "",
          m4: "",
          icd_1: "",
          icd_2: "",
          icd_3: "",
          icd_4: "",
          unit: 1,
          charge: "",
          total: "",
          remarks: "",
          cptCodeError: false,
          unitError: false,
          chargeError: false,
          icdError: false,
        },
      ],
      accessionCPT: [],
      totalCPTSum: "",
      totalUnitsSum: "",
      viewForm: false,
      utiCPT: [],
      utiCPTCodesData: [],
      icdCheckboxChecked: false,
      icd2CheckboxChecked: false,
      icd3CheckboxChecked: false,
      icd4CheckboxChecked: false,
    };
  },
  computed: {
    ...mapGetters("cases", [
      "getCPTForAccession",
      "getAddCPT",
      "getCPTTestInfo",
      "getCaseInformation",
      "getCptCodesForUTI",
      "getPayableICDCodes",
    ]),
    isAddCPTButtonDisabled() {
      // Check if at least one value in addCPT is an empty string
      return this.addCPT.some(
        (row) =>
          row.unit &&
          row.cpt_code &&
          (row.icd_1 || row.icd_2 || row.icd_3 || row.icd_4)
      );
    },
    totalSum() {
      let decimalPlaces = 1;
      // Calculate the sum of all "Total" values in the addCPT array
      return this.addCPT.reduce(
        (sum, row) =>
          sum + (Number(parseFloat(row.total).toFixed(decimalPlaces)) || 0),
        0
      );
    },
    hasBloodPanel() {
      if (this.getCaseInformation) {
        if (
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.indexOf("CLINICAL_CHEMISTRY") > -1
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    hasUTIPanel() {
      if (this.getCaseInformation) {
        return (
          this.getCaseInformation.case_types &&
          (this.getCaseInformation.case_types.includes("UTI") ||
            this.getCaseInformation.case_types.includes("WOUND") ||
            this.getCaseInformation.case_types.includes("NAIL") ||
            this.getCaseInformation.case_types.includes("DIABETES") ||
            this.getCaseInformation.case_types.includes("PULMONARY_PANEL") ||
            this.getCaseInformation.case_types.includes("CARDIAC") ||
            this.getCaseInformation.case_types.includes("CGX_PANEL") ||
            this.getCaseInformation.case_types.includes("PGX_TEST") ||
            this.getCaseInformation.case_types.includes(
              "RESPIRATORY_PATHOGEN_PANEL"
            ) ||
            this.getCaseInformation.case_types.includes("COVID") ||
            this.getCaseInformation.case_types.includes("COVID_FLU") ||
            this.getCaseInformation.case_types.includes("TOXICOLOGY"))
        );
      }
      return true;
    },
  },
  async mounted() {
    if (this.$route.params.case_id) {
      await this.fetchCPTCode();
      await this.fetchUTICptCodes();
      await this.fetchAllPayableIcdCodes();
    }
  },
  methods: {
    fillICD4() {
      if (this.icd4CheckboxChecked) {
        for (let i = 1; i < this.addCPT.length; i++) {
          this.addCPT[i].icd_4 = this.addCPT[0].icd_4 || "";
        }
      } else {
        for (let i = 1; i < this.addCPT.length; i++) {
          this.addCPT[i].icd_4 = "";
        }
      }
    },
    fillICD3() {
      if (this.icd3CheckboxChecked) {
        for (let i = 1; i < this.addCPT.length; i++) {
          this.addCPT[i].icd_3 = this.addCPT[0].icd_3 || "";
        }
      } else {
        for (let i = 1; i < this.addCPT.length; i++) {
          this.addCPT[i].icd_3 = "";
        }
      }
    },
    fillICD2() {
      if (this.icd2CheckboxChecked) {
        for (let i = 1; i < this.addCPT.length; i++) {
          this.addCPT[i].icd_2 = this.addCPT[0].icd_2 || "";
        }
      } else {
        for (let i = 1; i < this.addCPT.length; i++) {
          this.addCPT[i].icd_2 = "";
        }
      }
    },

    fillICD1() {
      // If the checkbox is checked
      if (this.icdCheckboxChecked) {
        // Fill the icd_1 value of all rows except the first row
        for (let i = 1; i < this.addCPT.length; i++) {
          this.addCPT[i].icd_1 = this.addCPT[0].icd_1 || ""; // Use the value of the first row's icd_1
        }
      } else {
        // Clear icd_1 value for all rows except the first row
        for (let i = 1; i < this.addCPT.length; i++) {
          this.addCPT[i].icd_1 = ""; // Clear icd_1 value
        }
      }
    },

    async fetchAllPayableIcdCodes() {
      let case_id = this.$route.params.case_id;
      await this.$store.dispatch("cases/fetchPayableICDCodes", case_id);
    },
    async fetchUTICptCodes() {
      let caseId = this.$route.params.case_id;
      await this.$store.dispatch("cases/fetchCPTCodesForUTI", caseId);
      if (this.getCptCodesForUTI && this.getCptCodesForUTI.data) {
        this.utiCPTCodesData = this.getCptCodesForUTI.data;
      }
    },

    async setCPTCodesAutofill() {
      try {
        this.loading = true;
        this.addCPT = [];

        if (this.getCptCodesForUTI && this.getCptCodesForUTI.data) {
          let selectedCategories = this.utiCPT;

          selectedCategories.forEach((selectedCategory) => {
            this.getCptCodesForUTI.data.forEach((categoryItem) => {
              if (
                categoryItem.category === selectedCategory &&
                categoryItem.list
              ) {
                categoryItem.list.forEach((item, index) => {
                  const { code: cpt_code, billed_value: charge } = item;

                  // Push data for each row
                  this.addCPT.push({
                    cpt_code,
                    charge,
                    unit: 1,
                    icd_1: "", // Initialize icd properties with empty string
                    icd_2: "",
                    icd_3: "",
                    icd_4: "",
                  });
                });
              }
            });
          });

          // Only fill ICD codes for the first row
          if (
            this.getPayableICDCodes &&
            this.getPayableICDCodes.matched_icd_codes &&
            this.getCaseInformation &&
            this.getCaseInformation.icd_10_codes
          ) {
            let matchedCodes = this.getPayableICDCodes.matched_icd_codes || [];
            let remainingCodes = this.getCaseInformation.icd_10_codes || [];

            let allICDCodes = new Set([...matchedCodes, ...remainingCodes]);
            let uniqueCode = [...allICDCodes];

            if (uniqueCode.length > 0) {
              // Only fill the icd properties for the first row
              this.addCPT[0]["icd_1"] = uniqueCode[0] || "";
              this.addCPT[0]["icd_2"] = uniqueCode[1] || "";
              this.addCPT[0]["icd_3"] = uniqueCode[2] || "";
              this.addCPT[0]["icd_4"] = uniqueCode[3] || "";
            }
            if (matchedCodes.length === 0 && remainingCodes.length > 0) {
              errorNotification("No Payable ICD Codes available");
            }
          }

          // Update total for all rows
          this.addCPT.forEach((item) => {
            this.updateTotal(item);
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async syncTestinfo() {
      try {
        this.loading = true;
        let caseId = this.$route.params.case_id;
        await this.$store.dispatch("cases/fetchTestinfoForCase", caseId);

        if (this.getCPTTestInfo && this.getCPTTestInfo.data) {
          const uniqueCPTMap = new Map();

          this.getCPTTestInfo.data.forEach((item) => {
            const {
              code: cpt_code,
              billied_value: charge,
              category,
              category_code,
            } = item;
            const test_name =
              category_code === "ALL_TESTS" ? item.name : category;

            if (!uniqueCPTMap.has(cpt_code)) {
              uniqueCPTMap.set(cpt_code, { charge, test_name });
            }
          });

          const cptArray = Array.from(uniqueCPTMap);

          this.addCPT = cptArray.map(([cpt_code, { charge, test_name }]) => ({
            cpt_code,
            charge,
            test_name,
            unit: 1,
            icd_1: "",
            icd_2: "",
            icd_3: "",
            icd_4: "",
          }));

          // Only fill ICD codes for the first row
          if (
            this.getPayableICDCodes &&
            this.getPayableICDCodes.matched_icd_codes &&
            this.getCaseInformation &&
            this.getCaseInformation.icd_10_codes
          ) {
            let matchedCodes = this.getPayableICDCodes.matched_icd_codes || [];
            let remainingCodes = this.getCaseInformation.icd_10_codes || [];

            let allICDCodes = new Set([...matchedCodes, ...remainingCodes]);
            let uniqueCode = [...allICDCodes];

            if (uniqueCode.length > 0) {
              // Only fill the icd properties for the first row
              this.addCPT[0]["icd_1"] = uniqueCode[0] || "";
              this.addCPT[0]["icd_2"] = uniqueCode[1] || "";
              this.addCPT[0]["icd_3"] = uniqueCode[2] || "";
              this.addCPT[0]["icd_4"] = uniqueCode[3] || "";
            }
            if (matchedCodes.length === 0 && remainingCodes.length > 0) {
              errorNotification("No Payable ICD Codes available");
            }
          }

          this.addCPT.forEach((item) => {
            this.updateTotal(item);
          });
        } else {
          // Handle the case when getCPTTestInfo.data is not available
        }

        this.loading = false;
      } catch (err) {
        console.error(err);
      }
    },

    updateChargeOnCPTChange(row) {
      const cptCode = row.cpt_code;
      const charge = CPTCodes[cptCode] ? String(CPTCodes[cptCode][0]) : "0";
      row.charge = charge;
      this.updateTotal(row);
    },
    updatedEditForm() {
      this.viewForm = false;
    },
    updateTotal(row) {
      // Ensure row.unit and row.charge are strings
      row.unit = String(row.unit).replace(/[^0-9]/g, "");
      // row.charge = String(row.charge).replace(/[^0-9]/g, "");

      // Update the Total field based on the product of the Unit and Charge values
      let total = (parseInt(row.unit) || 0) * (parseFloat(row.charge) || 0);
      let decimalPlaces = 1; // Change this to the desired number of decimal places

      row.total = Number(total.toFixed(decimalPlaces));
    },
    updatechareTotal(row) {
      const unit = parseInt(row.unit) || 0;
      const charge = parseInt(row.charge) || 0;

      if (isNaN(unit) || isNaN(charge)) {
        console.error("Invalid unit or charge values");
        return;
      }

      // Update the Total field based on the product of the Unit and Charge values
      row.total = unit * charge;
    },
    validateForm() {
      let isValid = true;
      this.addCPT.forEach((row, index) => {
        // Check for empty cpt_code
        if (!row.cpt_code.trim()) {
          row.cptCodeError = true;
          isValid = false;
        } else {
          row.cptCodeError = false;
        }

        // Check for empty unit
        if (!row.unit) {
          row.unitError = true;
          isValid = false;
        } else {
          row.unitError = false;
        }

        if (!row.charge) {
          row.chargeError = true;
          isValid = false;
        } else {
          row.chargeError = false;
        }
        if (!row.icd_1) {
          row.icdError = true;
          isValid = false;
        } else {
          row.icdError = false;
        }
      });

      return isValid;
    },

    async addCPTCodes() {
      if (!this.validateForm()) {
        return;
      }
      this.loading = true;
      try {
        let params = {
          case_id: this.$route.params.case_id,
          cpt_coding_details: this.addCPT.map(
            ({ cptCodeError, unitError, chargeError, icdError, ...rest }) =>
              rest
          ),
          cpt_coding_charges_final_sum: this.totalSum,
        };
        await this.$store.dispatch("cases/addCPTForAccession", params);
        if (this.getAddCPT) {
          successNotification("Cpt codes updated successfully");
          this.addCPT = [
            {
              cpt_code: "",
              test_name: "",
              m1: "",
              m2: "",
              m3: "",
              m4: "",
              icd_1: "",
              icd_2: "",
              icd_3: "",
              icd_4: "",
              unit: "",
              charge: "",
              total: "",
              status: false,
              remarks: "",
            },
          ];
          window.location.reload();
        } else {
          if (!this.getErrors || this.getError) {
            errorNotification("Error while cpt codes");
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    addNewRow(existingRow) {
      const newRow = {
        cpt_code: "",
        test_name: "",
        m1: "",
        m2: "",
        m3: "",
        m4: "",
        icd_1: existingRow.icd_1,
        icd_2: existingRow.icd_2,
        icd_3: existingRow.icd_3,
        icd_4: existingRow.icd_4,
        unit: 1,
        charge: "",
        total: "",
        remarks: "",
        cptCodeError: false,
        unitError: false,
        chargeError: false,
        icdError: false,
      };
      this.addCPT.push(newRow);
    },
    removeRow(row) {
      const index = this.addCPT.indexOf(row);
      if (index !== -1) {
        this.addCPT.splice(index, 1);
      }
    },

    async fetchCPTCode() {
      try {
        let params = {
          case_id: this.$route.params.case_id,
        };
        await this.$store.dispatch("cases/fetchCPTForAccession", params);
        if (
          this.getCPTForAccession &&
          this.getCPTForAccession.data &&
          this.getCPTForAccession.data[0] &&
          this.getCPTForAccession.data[0].cpt_coding_charges_final_sum
        ) {
          this.totalCPTSum =
            this.getCPTForAccession.data[0].cpt_coding_charges_final_sum;
          this.totalUnitsSum =
            this.getCPTForAccession.data[0].cpt_coding_charges_final_sum;
        }
        if (
          this.getCPTForAccession &&
          this.getCPTForAccession.data &&
          this.getCPTForAccession.data[0] &&
          this.getCPTForAccession.data[0].cpt_coding_details.length != 0
        ) {
          this.viewForm = true;
          this.addCPT = this.getCPTForAccession.data[0].cpt_coding_details.map(
            (item) => ({
              cpt_code: item.cpt_code,
              test_name: item.test_name || "",
              m1: item.m1,
              m2: item.m2,
              m3: item.m3,
              m4: item.m4,
              icd_1: item.icd_1,
              icd_2: item.icd_2,
              icd_3: item.icd_3,
              icd_4: item.icd_4,
              unit: item.unit,
              charge: item.charge,
              total: item.total,
              remarks: item.remarks,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
.test_name_input {
  width: 100px !important;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}
.cpt-el-select {
  position: relative;
  right: 15px;
  margin: 7px 0;
  .el-select {
    height: 36px;

    .el-input__inner {
      height: 35px;
      background: #fff;
      border: 1px solid hsl(0, 0%, 88%);
      box-shadow: none;
      padding-left: 30px;
      font-weight: 500;
      color: #000;
    }

    .el-input__suffix {
      // background: #0a5da6;
      background: #273480;
      border-radius: 0 5px 5px 0;
      right: 0;
      height: 98%;

      .el-icon {
        color: #fff !important;
        font-weight: 800;
      }
    }
  }
  .el-select__input {
    margin-left: 30px !important;
  }
  .search-icon {
    position: absolute;
    left: 6px;
    top: 0;
    bottom: 0;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
    }
  }
}
</style>
